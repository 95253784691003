
/*!
 *  Intro text and graphic.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./intro.scss";

import Coop from "Classes/Coop";
import LoadImage from "Components/Layout/LoadImage";
import Points from "Components/Layout/Points";

import Heading1x from "./heading.png";
import Heading2x from "./heading@2x.png";

class Intro extends React.Component {

    constructor( props ) {

        super( props );

        this.Random = true;
        this.RandomNumbers = this.Random ? [ 1, 2, 3, 4, 5, 6 ] : false;

        this.state = {

            points: 0

        };
        
    }

    componentDidMount() {

        if ( this.Random ) {

            return;

        }

        this.setState( {

            points: Coop.GetPointsPerUnit()

        } );

        Coop.Listen( "points_per_unit", this.OnPoints );

    }

    componentWillUnmount() {

        if ( this.Random ) {

            return;

        }

        Coop.Remove( "points_per_unit", this.OnPoints );

    }

    OnPoints = ( points ) => {

        this.setState( { points } );

    }

    render() {

        const { points } = this.state;

        return (

            <div className="Intro">

                <div className="IntroContent">

                    <div className="IntroContentText">

                        <LoadImage
                        
                            alt="Missa inte poängen."
                            autoAdjust={ true }
                            className="IntroContentHeading"
                            src={ Heading1x }
                            src2x={ Heading2x }
                        
                        />

                        <p>
                            
                            Som medlem i Coop tjänar du poäng på redan rabatterade priser när du handlar hos våra utvalda partners, på sådant du ändå behöver. Här nedan kan du räkna ut hur mycket mer poäng du kan tjäna genom att handla på Coop och hos våra partners via <a rel="noopener noreferrer" target="_blank" href="https://coop.se">coop.se</a>.
                            
                        </p>

                    </div>

                    <Points
                    
                        points={ points }
                        random={ this.RandomNumbers }
                        
                    />

                </div>

            </div>

        );

    }

}

export default Intro;