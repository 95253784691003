
/*!
 *  Collapsable view.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean collapsed - Whether the view should be collapsed.
 *  @prop integer collapseHeight - Height when the view is collapsed.
 *  @prop float duration - Duration of the transition between expand/collpase in seconds.
 *  @prop function onOverflow - Callback when the container toggles between having overflow or not.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./collapsable.scss";

import { CSSPlugin, TweenLite } from "gsap";
import { Power1 } from "gsap/all";

class Collapsable extends React.Component {

    /**
     * Expand/collapse on mount.
     * 
     * @return void
     */

    componentDidMount() {

        if ( CSSPlugin ) {}

        const { collapsed } = this.props;
        
        this.CheckOverflow();
        this.Toggle( collapsed, true );

        window.addEventListener( "resize", this.CheckOverflow );

    }

    /**
     * Expand/collapse when the prop changes.
     * 
     * @return void
     */

    componentWillReceiveProps( nextProps ) {

        const { collapsed } = nextProps;

        if ( collapsed !== this.props.collapsed ) {

            this.Toggle( collapsed );

        }

    }

    /**
     * Remove listeners on unmount.
     * 
     * @return void
     */

    componentWillUnmount() {

        window.removeEventListener( "resize", this.CheckOverflow );

    }

    /**
     * Check whether the container has overflow, ie. if the collapseHeight is
     * shorter than the contents.
     * 
     * @return void
     */

    CheckOverflow = () => {

        const { collapseHeight, onOverflow } = this.props;
        const { content } = this.refs;

        onOverflow( content ? content.offsetHeight > collapseHeight : false );

    }

    /**
     * Expand/collapse the view.
     * 
     * @param boolean collapsed - Whether the view should collapse.
     * @param boolean initial - Set duration to '0' for the initial setup.
     * 
     * @return void
     */

    Toggle = ( collapsed, initial ) => {

        const { collapseHeight, duration } = this.props;
        const { container, content } = this.refs;

        if ( !container || !content ) {

            return;

        }

        const Height = collapsed ? collapseHeight : content.offsetHeight;
        const Duration = initial ? 0 : duration;

        TweenLite.to( container, Duration, {

            ease: Power1.easeInOut,
            height: Height + "px",
            onComplete: () => {

                if ( !collapsed ) {

                    container.style.height = "auto";

                }

            }

        } );

    }

    render() {

        const { children, className, collapsed, collapseHeight } = this.props;
        const CA = [ "Collapsable" ];

        if ( className ) {

            CA.push( className );

        }

        if ( collapsed && collapseHeight ) {

            CA.push( "SemiCollapsed" );

        }

        else if ( collapsed ) {

            CA.push( "Collapsed" );

        }

        else {

            CA.push( "Expanded" );

        }

        const CS = CA.join( " " );

        return (

            <div className={ CS } ref="container">

                <div className="CollapsableContent" ref="content">

                    { children }

                </div>

            </div>

        );

    }

}

Collapsable.propTypes = {

    className: PropTypes.string,
    collapsed: PropTypes.bool,
    collapseHeight: PropTypes.number,
    duration: PropTypes.number,
    onOverflow: PropTypes.func

};

Collapsable.defaultProps = {

    className: "",
    collapsed: true,
    collapseHeight: 0,
    duration: .75,
    onOverflow: () => {}

}

export default Collapsable;