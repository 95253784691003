
/*!
 *  Range slider.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the slider should be disabled.
 *  @prop string id - The element id.
 *  @prop integer max - Maximum value.
 *  @prop integer min - Minimum value.
 *  @prop boolean mirror - Whether to mirror the range slider.
 *  @prop function onChange - Callback when the value is changed.
 *  @props integer value - Initial value.
 *
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./rangeslider.scss";

import { CapFloat } from "Functions";

class RangeSlider extends React.Component {

    constructor( props ) {

        super( props );

        this.Direction = false;
        this.Origin = [ 0, 0 ];
        this.Range = [ 0, 0 ];

        this.state = {

            value: 0

        };

    }

    /*
     * Set value on mount.
     * 
     * @return void
     */

    componentDidMount() {

        const { value } = this.props;

        this.setState( { value } );

    }

    /*
     * Update the range slider when a new value is set.
     * 
     * @return void
     */

    componentWillReceiveProps( nextProps ) {

        const { value } = nextProps;

        if ( value !== this.props.value ) {

            this.setState( { value } );

        }

    }

    /*
     * Update the value when the slider is clicked.
     *
     * @param object e - The click event.
     * 
     * @return void
     */

    OnClick = (e) => {

        const { disabled, id, max, min, mirror, onChange } = this.props;
        const { slider } = this.refs;
        const { pageX } = e;

        if ( e.button !== 0 || !slider || disabled ) {

            return;

        }

        const Rect = slider.getBoundingClientRect();
        const Offset = Rect.left;
        const Width = slider.offsetWidth;

        let Progress = CapFloat( ( pageX - Offset ) / Width );

        if ( mirror ) {

            Progress = 1 - Progress;

        }

        const Value = Math.round( Progress * ( max - min ) );

        onChange( e, Value, id );

        this.setState( {

            value: Value
        
        } );

    }

    /*
     * Callback when drag stops.
     *
     * @param object e - The mouse event.
     * 
     * @return void
     */

    OnDragEnd = (e) => {

        window.removeEventListener( "mousemove", this.OnDragMove );
        window.removeEventListener( "mouseup", this.OnDragEnd );

    }

    /*
     * Callback when dragged.
     *
     * @param object e - The mouse event.
     * @param boolean checkDirection - Whether to block interaction when moving mostly in the Y-direction.
     * 
     * @return void
     */

    OnDragMove = ( e, checkDirection ) => {

        if ( checkDirection && this.Direction === 1 ) {

            return;

        }

        const { pageX, pageY } = e;

        if ( checkDirection && this.Direction === false ) {

            const [ OX, OY ] = this.Origin;
            const SX = Math.abs( pageX - OX );
            const SY = Math.abs( pageY - OY );

            if ( SY > SX ) {

                this.Direction = 1;
                return;

            }

            if ( SX > SY ) {

                this.Direction = 0;

            }

        }

        const { id, max, min, mirror, onChange } = this.props;
        const [ Offset, Width ] = this.Range;

        let Progress = CapFloat( ( pageX - Offset ) / Width );

        if ( mirror ) {

            Progress = 1 - Progress;

        }

        const Value = Math.round( Progress * ( max - min ) );

        onChange( e, Value, id );

        this.setState( {

            value: Value
        
        } );

    }

    /*
     * Callback when a drag is initiated.
     *
     * @param object e - The mouse event.
     * 
     * @return void
     */

    OnDragStart = (e) => {

        const { disabled } = this.props;
        const { slider } = this.refs;
        const { button, pageX, pageY } = e;

        if ( button !== 0 || !slider || disabled ) {

            return;

        }

        e.preventDefault();
        e.stopPropagation();

        const Rect = slider.getBoundingClientRect();
        const Offset = Rect.left;
        const Width = slider.offsetWidth;

        this.Direction = false;
        this.Origin = [ pageX, pageY ];
        this.Range = [ Offset, Width ];

        window.addEventListener( "mousemove", this.OnDragMove );
        window.addEventListener( "mouseup", this.OnDragEnd );

    }

    /*
     * Callback when touch dragged.
     *
     * @param object e - The touch event.
     * 
     * @return void
     */

    OnTouchMove = (e) => {

        e.stopPropagation();

        const E = e.touches[0];

        this.OnDragMove( E, true );

    }

    /*
     * Callback when a touch drag is initiated.
     *
     * @param object e - The touch event.
     * 
     * @return void
     */

    OnTouchStart = (e) => {

        const { disabled } = this.props;
        const { slider } = this.refs;
        const { pageX, pageY } = e.touches[0];

        if ( !slider || disabled ) {

            return;

        }

        e.stopPropagation();

        const Rect = slider.getBoundingClientRect();
        const Offset = Rect.left;
        const Width = slider.offsetWidth;

        this.Direction = false;
        this.Origin = [ pageX, pageY ];
        this.Range = [ Offset, Width ];

    }

    render() {

        const { className, disabled, max, min, mirror } = this.props;
        const { value } = this.state;
        const CA = [ "RangeSlider" ];

        if ( className ) CA.push( className );
        if ( disabled ) CA.push( "Disabled" );
        if ( mirror ) CA.push( "Mirror" );

        const CS = CA.join( " " );
        const Offset = CapFloat( ( value - min ) / ( max - min ) ) * 100 + "%";

        return (

            <div
            
                className={ CS }
                onClick={ this.OnClick }
                onMouseDown={ this.OnDragStart }
                onTouchStart={ this.OnTouchStart }
                onTouchMove={ this.OnTouchMove }
                ref="slider"
                
            >

                <div className="RangeSliderFill" style={{ width: Offset }} />
            
                <div className="RangeSliderHandleContainer" style={ mirror ? { right: Offset } : { left: Offset } }>

                    <div className="RangeSliderHandle" />
                
                </div>
            
            </div>

        );

    }

}

RangeSlider.propTypes = {

    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    mirror: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.number

};

RangeSlider.defaultProps = {

    className: "",
    disabled: false,
    id: "",
    max: 1000,
    min: 0,
    mirror: false,
    onChange: () => {},
    value: 500

};

export default RangeSlider;