
/*!
 *  "Mer som medlem" logo.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./memberlogo.scss";

class MemberLogo extends React.Component {

    Svg = () => {

        return (

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 279.88 274.37">
                
                <path className="MemberLogoWhite" d="M26.68,22.34S4.64,22,4.25,44L.67,248.33s-.38,22,21.66,22.43l207.39,3.61s22,.38,22.43-21.66l3.58-204.33s.38-22.05-21.66-22.43Z" transform="translate(-0.67)"/>
                
                <path className="MemberLogoGreen" d="M36.71,54.05l5.23.08q2.63.06,5.22.27L61,54.73l2.58,5.74a28.35,28.35,0,0,0,2.31,6.09q1.59,3.14,2.76,5.14c1.31-2.57,2.52-5.22,3.64-8s2.26-5.48,3.46-8.23c2.25-.13,4.49-.16,6.7-.1s4.44.15,6.69.25c2.42,0,4.8,0,7.17-.09a54,54,0,0,1,7.16.34q.09,4.5.06,8.94c0,3-.12,5.94-.29,8.93l-.37,16.67-.27,20.2c0,1.38-.06,2.78-.11,4.19a39,39,0,0,0,.05,4.19,7.81,7.81,0,0,1-2.68.34c-.92,0-1.81-.08-2.68-.09q-1.82,0-3.93.06a34.1,34.1,0,0,1-3.92-.2c-.75,0-1.49,0-2.2-.08a18.41,18.41,0,0,1-2.46-.34c0-1.27,0-2.42,0-3.46s0-2.06.06-3.06.06-2,.14-3.07.12-2.19.14-3.45l0-16.24c0-1.49,0-3,0-4.49a28.27,28.27,0,0,0-.27-4.49,39.61,39.61,0,0,0-2.42,5.18l-2,5.19-5.13,12c-.66,1.31-1.31,2.57-2,3.76s-1.31,2.37-2,3.51a6.58,6.58,0,0,1-2.55.18c-1-.11-1.91-.22-2.89-.36a55.14,55.14,0,0,1-2.49-5.65c-.71-1.92-1.43-3.8-2.15-5.66L56.33,87.73l-3.64-8.88a31.3,31.3,0,0,0-.59,6.86q.06,3.58,0,7.12c0,2.42,0,4.8,0,7.13s-.1,4.7-.26,7.12c0,2,0,3.89.07,5.79a21.86,21.86,0,0,1-.61,5.51l-7.6,0c-1.61.09-3.18.11-4.71,0a26.46,26.46,0,0,1-4.52-.55l-.15-16.33.34-14.32-.06-16.51.53-15.45.36-1Z" transform="translate(-0.67)"/>
                
                <path className="MemberLogoGreen" d="M110.17,56.19q9.67.09,19.51.38t19.51.56c2.19,0,4.33,0,6.44-.1s4.28-.11,6.52,0c-.05,2.71-.05,5.49,0,8.33a78.7,78.7,0,0,1-.26,7.9,32.28,32.28,0,0,1-6.36.32q-3.31-.15-6.17-.1c-2.82,0-5.37,0-7.65,0s-4.82,0-7.63-.22a19.87,19.87,0,0,0-2.64,0,5.1,5.1,0,0,0-2.3.48c0,1.32,0,2.57,0,3.75a33.1,33.1,0,0,1-.19,3.76c1.32,0,2.52.06,3.58.1s2.26.06,3.58,0q7.78-.12,15.64,0a29.68,29.68,0,0,1,.2,3.11c0,1.33,0,2.69,0,4.1s-.11,2.71-.24,3.88a7.74,7.74,0,0,1-.57,2.45c-3.79-.12-7.55-.21-11.26-.28s-7.47-.07-11.27,0c-.17,0-.32.37-.45,1.11s-.25,1.59-.35,2.55-.18,1.89-.22,2.84-.07,1.6-.08,1.94q8.46.06,16.83.34c5.59.19,11.2.38,16.84.6.15,1.33.24,2.76.27,4.32s0,3.1-.08,4.62-.2,2.93-.34,4.22-.27,2.37-.4,3.23a45,45,0,0,1-4.79,0l-4.79-.26c-1.72-.08-3.54-.12-5.44-.1s-3.71,0-5.44-.17a8.28,8.28,0,0,0-2.37,0,11.15,11.15,0,0,1-2.55,0c-1.32-.08-2.63-.14-3.93-.19s-2.6-.06-3.93,0-2.79,0-4.23,0l-4-.07-3.28,0-2,0h-.69a10.21,10.21,0,0,1-1.3-.11l-1.89-.2a52.1,52.1,0,0,1-.24-6.05q0-2.94,0-6c0-.92,0-1.77,0-2.54s0-1.55,0-2.3,0-1.51,0-2.28,0-1.66,0-2.64-.07-2.25-.11-3.63,0-2.59,0-3.62a57,57,0,0,0,.23-8.34q-.18-4.1-.2-8.25t.07-8.67Q109.76,60.24,110.17,56.19Z" transform="translate(-0.67)"/>
                
                <path className="MemberLogoGreen" d="M204,57.57l5.6.49a20.38,20.38,0,0,1,4.74.9,16.42,16.42,0,0,1,5.81,3.38,17.73,17.73,0,0,1,3.8,5,23,23,0,0,1,2.23,5.83c.43,1.9.77,3.84,1,5.8a26.09,26.09,0,0,1-.9,7.32,15.32,15.32,0,0,1-2.6,5.31,21.18,21.18,0,0,1-4.65,3.85c-1.28.7-2.41,1.36-3.4,2l4.16,9.32,4.91,10.36a13.13,13.13,0,0,1,1.74,4.26l-6.65.23-6.56-.11a10,10,0,0,1-1-.11l-1.46-.2-1.51-.19a4.8,4.8,0,0,1-1.16-.28,36.65,36.65,0,0,1-3-5.71q-1.08-2.66-2.5-5.44l-4.16-8.8a4.31,4.31,0,0,0-.42-.83,1.43,1.43,0,0,0-.77-.57q-1.81.14-3.63.15c-1.21,0-2.41,0-3.63-.11-.8-.07-1.58-.13-2.32-.17a13.31,13.31,0,0,0-2.34.09A32.74,32.74,0,0,0,185,104c0,1.3,0,2.52,0,3.67.06,3,0,6-.16,8.9,0,.8-.05,1.53-.12,2.15s-.17,1.27-.29,1.9a35,35,0,0,1-6.92.39c-1.27.1-2.52.13-3.76.11s-2.49-.1-3.75-.24c-1.56,0-2.47-.27-2.75-.91a19,19,0,0,1-.23-6.22L167,94.32l.52-15.19.19-10.88c0-1,0-2.08,0-3.15s-.05-2.12,0-3.15.06-2,.13-2.9a8.23,8.23,0,0,1,.48-2.28,76.67,76.67,0,0,1,8.55-.23c2.82.07,5.67.08,8.55,0ZM185.35,83.76a37.18,37.18,0,0,0,4.78.47c1.58.06,3.18.07,4.8,0a15.55,15.55,0,0,0,2.76.14c.74,0,1.67-.06,2.76,0q1.38-.15,2.64-.39a2.84,2.84,0,0,0,1.79-.92,3.15,3.15,0,0,0,1.37-1.66,5.12,5.12,0,0,0,.16-2.2,4.31,4.31,0,0,0-.72-2.9,6.48,6.48,0,0,0-2.13-1.9c-.52-.12-1.09-.25-1.72-.37a18.9,18.9,0,0,0-2.15-.3,26.61,26.61,0,0,0-3-.23l-3,0c-1.32-.08-2.63-.14-3.92-.19a36.88,36.88,0,0,0-3.93,0c-.14,1-.24,1.91-.31,2.81s-.12,1.82-.14,2.8Z" transform="translate(-0.67)"/>
                
                <path className="MemberLogoGreenYellow" d="M80.57,140.44a22.35,22.35,0,0,1-.38,7,50,50,0,0,1-6.34.14q-3.12-.14-6.33-.54c-2.14,0-4.11,0-5.92,0s-3.52-.11-5.15-.26a5.07,5.07,0,0,0-3.43.75,2.36,2.36,0,0,0-1.09,1.63,2.23,2.23,0,0,0,.94,2.17,9.69,9.69,0,0,0,3.15.95l6.81,1.72q3,.56,5.81,1.2t5.56,1.53a23.77,23.77,0,0,1,4.52,2.49,39.92,39.92,0,0,1,3.67,3,14.12,14.12,0,0,1,3.13,4.7A18.91,18.91,0,0,1,86.61,172c.15,1.3.24,2.56.27,3.76a18.78,18.78,0,0,1-1.66,8.35,34.28,34.28,0,0,1-2.1,3.81,13.53,13.53,0,0,1-3.65,3.27,32.67,32.67,0,0,1-5,2.49c-1.52.26-3.07.47-4.62.64a30.24,30.24,0,0,1-4.78.13l-16.39-.37-11.06-.28c-1-.08-2-.16-3-.26s-2-.25-3-.44c-.09-1.46-.12-2.87-.1-4.22s.08-2.71.16-4.06l.36-6.25a9.8,9.8,0,0,1,.38-2.44q3,.56,6.12.86t6.37.54c1.4.14,2.81.26,4.22.37s3,.18,4.73.21l7.78-.37a10.82,10.82,0,0,0,2.92-.17,5.21,5.21,0,0,0,2.09-.76,2.17,2.17,0,0,0,.86-1.09,4.15,4.15,0,0,0,.11-1.52,3.85,3.85,0,0,0-2.13-3.51,7.2,7.2,0,0,0-2.23-.93,26.41,26.41,0,0,0-2.82-.51L50.27,166.8l-6.22-2.05a27.5,27.5,0,0,1-6.94-4.44,14,14,0,0,1-2-2.58,11,11,0,0,1-1.42-3.32,35.94,35.94,0,0,1-.89-7,13.62,13.62,0,0,1,.22-3.08c.19-.93.46-2,.82-3.34A21.59,21.59,0,0,1,36.55,137a13,13,0,0,1,3.73-3.2,16.45,16.45,0,0,1,3.33-1.63,29.41,29.41,0,0,1,3.31-1,14.35,14.35,0,0,1,3.85-.48,37.85,37.85,0,0,1,4.86.12c1.52-.08,3-.1,4.52,0l4.52.21,7.73.31c2.56.1,5.2.31,7.9.64Z" transform="translate(-0.67)"/>
                
                <path className="MemberLogoGreenYellow" d="M154.71,164.08a47.6,47.6,0,0,1-.7,6.12,37.76,37.76,0,0,1-1.58,5.93,28.79,28.79,0,0,1-2.63,5.45,24.75,24.75,0,0,1-3.76,4.67,44.81,44.81,0,0,1-10.23,7.21,28.49,28.49,0,0,1-12,3,52.35,52.35,0,0,1-7.39-.63,31.42,31.42,0,0,1-6.52-1.81,27.48,27.48,0,0,1-6-3.36,49.47,49.47,0,0,1-6-5.21,37.6,37.6,0,0,1-6.35-11.28,32.38,32.38,0,0,1-1.8-10.93,41.15,41.15,0,0,1,.75-6.41A26.73,26.73,0,0,1,92.34,151a35.87,35.87,0,0,1,3.23-5.61,56.65,56.65,0,0,1,5-6,31.23,31.23,0,0,1,9.32-6,31.61,31.61,0,0,1,12.22-2.37,37.25,37.25,0,0,1,6.2.66,47.32,47.32,0,0,1,6,1.54,28.89,28.89,0,0,1,5.16,2.25,18.35,18.35,0,0,1,3.76,2.68,34.12,34.12,0,0,1,8.76,11.4A29.64,29.64,0,0,1,154.71,164.08ZM122.09,148.8A14.23,14.23,0,0,0,111.67,153a14.92,14.92,0,0,0-3.08,4.6,14.6,14.6,0,0,0-1.2,5.69,13.22,13.22,0,0,0,.89,4.7,18.21,18.21,0,0,0,2.66,4.91,15.82,15.82,0,0,0,4.2,3.87,10.3,10.3,0,0,0,5.51,1.62,14.39,14.39,0,0,0,10.77-4.42,14.61,14.61,0,0,0,3-4.72,16.27,16.27,0,0,0,1.11-5.64,18.41,18.41,0,0,0-.63-5.13,13,13,0,0,0-2.25-4.56,12.18,12.18,0,0,0-4.16-3.41A16.62,16.62,0,0,0,122.09,148.8Z" transform="translate(-0.67)"/>
                
                <path className="MemberLogoGreenYellow" d="M161.42,132.92l5.11.09c1.72,0,3.42.12,5.11.26l13.52.32,2.53,5.63a27.8,27.8,0,0,0,2.26,5.95c1,2,1.93,3.73,2.7,5q1.92-3.76,3.56-7.79t3.39-8.06q3.3-.19,6.56-.1c2.16.07,4.35.15,6.54.25q3.56,0,7-.09a51.8,51.8,0,0,1,7,.33q.09,4.39.06,8.75t-.28,8.74l-.37,16.3-.26,19.79c0,1.35-.06,2.72-.11,4.09a37.3,37.3,0,0,0,0,4.1,7.5,7.5,0,0,1-2.63.34q-1.35-.07-2.61-.09c-1.19,0-2.47.05-3.85.06a32.79,32.79,0,0,1-3.84-.2c-.74,0-1.45,0-2.16-.08a17.49,17.49,0,0,1-2.4-.33c0-1.24,0-2.37,0-3.39s0-2,0-3,.07-2,.14-3,.12-2.14.15-3.38l0-15.89c0-1.47,0-2.93,0-4.39a28.38,28.38,0,0,0-.26-4.41,39.36,39.36,0,0,0-2.37,5.08c-.65,1.7-1.3,3.4-2,5.07l-5,11.75c-.64,1.28-1.28,2.51-1.93,3.69s-1.28,2.32-1.91,3.43a6.54,6.54,0,0,1-2.5.17c-.93-.1-1.87-.22-2.83-.35a55.19,55.19,0,0,1-2.44-5.54q-1-2.81-2.1-5.53l-4.71-10.64-3.57-8.69a30.6,30.6,0,0,0-.58,6.71q.06,3.51,0,7t0,7q0,3.42-.25,7c0,1.91,0,3.8.07,5.66a21.92,21.92,0,0,1-.6,5.4l-7.44,0c-1.58.09-3.12.1-4.61,0a25.42,25.42,0,0,1-4.43-.54l-.14-16,.33-14-.06-16.15.52-15.12.36-1Z" transform="translate(-0.67)"/>
                
                <path className="MemberLogoGreenDark" d="M31.37,206.07l2.55.05c.86,0,1.71.06,2.56.13l6.76.16,1.26,2.81a14.31,14.31,0,0,0,1.13,3c.52,1,1,1.86,1.35,2.51.64-1.25,1.24-2.55,1.78-3.9s1.11-2.68,1.7-4c1.1-.06,2.19-.08,3.28,0l3.27.12,3.51,0A25.85,25.85,0,0,1,64,207c0,1.46,0,2.93,0,4.38s0,2.9-.14,4.36l-.18,8.16-.13,9.89c0,.67,0,1.36-.06,2a17.5,17.5,0,0,0,0,2.05,3.63,3.63,0,0,1-1.31.16l-1.32,0q-.88,0-1.92,0a14.48,14.48,0,0,1-1.92-.1c-.37,0-.73,0-1.08,0a7.49,7.49,0,0,1-1.2-.17c0-.61,0-1.18,0-1.68l0-1.51c0-.49,0-1,.06-1.5S55,232,55,231.34v-8c0-.73,0-1.47,0-2.2a13.6,13.6,0,0,0-.13-2.2,19.33,19.33,0,0,0-1.18,2.54c-.33.85-.65,1.7-1,2.54l-2.51,5.87c-.32.65-.64,1.26-1,1.85s-.64,1.15-1,1.71a3,3,0,0,1-1.25.08c-.46-.05-.93-.1-1.41-.17a25.78,25.78,0,0,1-1.22-2.76c-.35-.94-.7-1.86-1.05-2.77L41,222.55l-1.78-4.34a15.25,15.25,0,0,0-.3,3.35c0,1.18,0,2.34,0,3.49s0,2.35,0,3.49,0,2.3-.12,3.48c0,1,0,1.91,0,2.84a11,11,0,0,1-.3,2.7l-3.72,0a22.24,22.24,0,0,1-2.3,0,13.74,13.74,0,0,1-2.22-.27l-.07-8,.17-7,0-8.07.26-7.56.17-.5Z" transform="translate(-0.67)"/>
                
                <path className="MemberLogoGreenDark" d="M68.5,207.14c3.15,0,6.34.09,9.55.19l9.55.27c1.06,0,2.11,0,3.14,0s2.1-.05,3.19,0c0,1.32,0,2.68,0,4.07s0,2.69-.13,3.87a15.87,15.87,0,0,1-3.11.15c-1.09,0-2.09-.06-3,0l-3.74,0c-1.12,0-2.37,0-3.74-.11a10.41,10.41,0,0,0-1.29,0,2.63,2.63,0,0,0-1.13.23c0,.65,0,1.26,0,1.84a14.79,14.79,0,0,1-.09,1.84l1.75.05c.52,0,1.1,0,1.75,0q3.81-.06,7.66,0c0,.37.08.87.1,1.52s0,1.32,0,2a18.06,18.06,0,0,1-.12,1.9,3.6,3.6,0,0,1-.27,1.2C86.68,226,84.84,226,83,226s-3.66,0-5.52,0c-.08,0-.16.18-.22.54s-.12.79-.17,1.25-.08.93-.11,1.39,0,.78,0,.95c2.77,0,5.51.08,8.24.16l8.24.3c.07.65.12,1.35.13,2.12s0,1.51,0,2.26-.09,1.43-.16,2.06-.14,1.16-.2,1.59a22.83,22.83,0,0,1-2.34,0l-2.34-.13c-.85,0-1.74-.06-2.67-.05a25.48,25.48,0,0,1-2.66-.08,4.16,4.16,0,0,0-1.16,0,5.64,5.64,0,0,1-1.25,0l-1.92-.09c-.63,0-1.27,0-1.92,0H74.85l-2,0-1.6,0-1,0H70c-.2,0-.41,0-.64,0l-.93-.11a27.08,27.08,0,0,1-.11-3c0-1,0-1.94,0-3,0-.45,0-.86,0-1.24v-1.12c0-.37,0-.74,0-1.13v-1.28c0-.51,0-1.11,0-1.78s0-1.27,0-1.78a27.89,27.89,0,0,0,.11-4.08c-.06-1.33-.09-2.68-.1-4s0-2.77,0-4.25S68.36,208.47,68.5,207.14Z" transform="translate(-0.67)"/>
                
                <path className="MemberLogoGreenDark" d="M97.92,238.09c0-1.55,0-3,0-4.18V231c0-.4,0-.85,0-1.36s0-1,0-1.45,0-.93,0-1.48l0-1.67c0-1.35,0-2.87,0-4.56L98,210.24c0-.37-.09-.71-.11-1a6.44,6.44,0,0,1,0-.76,1,1,0,0,1,.09-.51,6.12,6.12,0,0,1,.89-.11c.57-.05,1.22-.08,2-.11l2.28-.07h1.8l7.35.08c.87.08,1.79.21,2.74.39a23.47,23.47,0,0,1,2.72.7,16,16,0,0,1,2.37,1,6.18,6.18,0,0,1,1.75,1.36,28,28,0,0,1,3.07,4.21,12,12,0,0,1,1.6,4.11,18,18,0,0,1,.25,2.44q.06,1.36,0,3.27a11.57,11.57,0,0,1-.16,1.67c-.09.6-.2,1.19-.32,1.75s-.25,1.07-.39,1.51-.23.76-.29.93a10.22,10.22,0,0,1-.68,1.4,13.12,13.12,0,0,1-1.08,1.63,15.65,15.65,0,0,1-1.4,1.56,8.8,8.8,0,0,1-1.59,1.24,9.07,9.07,0,0,1-1.83.84,18,18,0,0,1-2.1.57c-.72.16-1.43.28-2.12.37a13.15,13.15,0,0,1-1.93.11c-.59,0-1.38,0-2.38,0s-2,0-3,0-2.32,0-3.59,0c-.64,0-1.47,0-2.47-.1A20.28,20.28,0,0,1,97.92,238.09Zm8.8-7.42a6.16,6.16,0,0,0,1,.15c.41,0,.83.06,1.26.06s.88,0,1.31,0a10.31,10.31,0,0,0,1.13-.1c.34-.06.73-.11,1.16-.17s.89-.15,1.34-.25a8.44,8.44,0,0,0,1.34-.45,5.25,5.25,0,0,0,1.26-.78,3.6,3.6,0,0,0,.86-1.21,6.9,6.9,0,0,0,.54-1.68,10.83,10.83,0,0,0,.2-1.9,13.2,13.2,0,0,0-.09-1.82c0-.28-.14-.66-.26-1.14a9.89,9.89,0,0,0-.52-1.53,12.74,12.74,0,0,0-.8-1.56,5.22,5.22,0,0,0-1-1.23,4.19,4.19,0,0,0-1.45-.72c-.57-.16-1.14-.31-1.7-.43s-1.32-.22-2-.28c-.14,0-.38,0-.7,0l-1.06,0q-.57,0-1.08.09a1.55,1.55,0,0,0-.68.17l-.14,8Z" transform="translate(-0.67)"/>
                
                <path className="MemberLogoGreenDark" d="M134.54,208.21c.73,0,1.45,0,2.17,0a13.81,13.81,0,0,1,2.13.29,34.81,34.81,0,0,1,.13,3.91c0,1.26-.08,2.52-.13,3.78l-.06,5.66.11,5.5c.05.65.07,1.31.08,2a11.28,11.28,0,0,0,.15,1.88,5.62,5.62,0,0,0,2.2,0l5.19.13,5.5.1,1.07,0a10.19,10.19,0,0,1,1.08.12,17.69,17.69,0,0,1,0,1.92c0,.64-.06,1.28-.1,1.93s0,1.33,0,2a14.74,14.74,0,0,1-.07,1.9c-1.27.07-2.5.1-3.7.09s-2.39,0-3.57,0l-8.41-.27c-1.35,0-2.7,0-4,0a39.17,39.17,0,0,1-3.94-.22.9.9,0,0,1-.28-.93l.13-5.41.07-6.25,0-6,.05-5.12v-5.45a5.2,5.2,0,0,1,.14-.81,4.78,4.78,0,0,1,.22-.7,12.47,12.47,0,0,1,1.89-.16Z" transform="translate(-0.67)"/>
                
                <path className="MemberLogoGreenDark" d="M158.2,208.71c3.15,0,6.34.09,9.54.18s6.4.19,9.55.28c1.07,0,2.12,0,3.15,0s2.09-.06,3.19,0c0,1.33,0,2.68,0,4.08a36.26,36.26,0,0,1-.13,3.86,15.75,15.75,0,0,1-3.1.16c-1.09-.05-2.1-.07-3-.05h-3.74c-1.11,0-2.36,0-3.73-.1a10.14,10.14,0,0,0-1.29,0,2.44,2.44,0,0,0-1.13.24c0,.65,0,1.26,0,1.84s0,1.19-.09,1.83c.64,0,1.23,0,1.75.06s1.1,0,1.75,0q3.81-.06,7.65,0c0,.37.08.88.1,1.52s0,1.32,0,2a18.06,18.06,0,0,1-.12,1.9,4.29,4.29,0,0,1-.27,1.21c-1.86-.07-3.7-.11-5.52-.15s-3.65,0-5.51,0c-.09,0-.16.18-.22.55s-.12.78-.17,1.24-.09.93-.11,1.4,0,.78,0,.95q4.14,0,8.24.16l8.24.29c.07.66.12,1.36.13,2.12s0,1.51,0,2.26-.1,1.44-.16,2.07-.14,1.16-.2,1.58a23,23,0,0,1-2.35,0l-2.34-.12c-.84-.05-1.73-.06-2.66-.05s-1.82,0-2.66-.09a4.14,4.14,0,0,0-1.16,0,5.66,5.66,0,0,1-1.25,0l-1.92-.1c-.64,0-1.28,0-1.93,0s-1.36,0-2.06,0l-2,0-1.6,0h-1.34a5,5,0,0,1-.63,0l-.93-.1a27.26,27.26,0,0,1-.11-3c0-1,0-1.95,0-3,0-.45,0-.87,0-1.25v-1.12c0-.37,0-.74,0-1.12s0-.81,0-1.29,0-1.1-.05-1.78,0-1.27,0-1.77a28,28,0,0,0,.11-4.08c-.06-1.33-.09-2.68-.1-4s0-2.77,0-4.25S158.06,210,158.2,208.71Z" transform="translate(-0.67)"/>
                
                <path className="MemberLogoGreenDark" d="M188.46,208.81l2.55.05c.86,0,1.71.06,2.55.13l6.77.16,1.26,2.81a13.77,13.77,0,0,0,1.13,3c.51,1,1,1.86,1.35,2.51.64-1.25,1.23-2.55,1.78-3.9s1.11-2.68,1.7-4a32.48,32.48,0,0,1,3.27-.05l3.28.12,3.5,0a25.92,25.92,0,0,1,3.51.16q0,2.21,0,4.38c0,1.45-.06,2.9-.14,4.37l-.19,8.15-.13,9.89c0,.68,0,1.36-.05,2.05a20,20,0,0,0,0,2.05,3.84,3.84,0,0,1-1.31.17c-.45,0-.89,0-1.31,0l-1.92,0a19.27,19.27,0,0,1-1.93-.1l-1.07,0a8.83,8.83,0,0,1-1.2-.17c0-.62,0-1.19,0-1.69s0-1,0-1.5,0-1,.07-1.5.06-1.07.07-1.69v-8c0-.73,0-1.47,0-2.2a14.84,14.84,0,0,0-.13-2.2,20,20,0,0,0-1.19,2.54c-.32.85-.64,1.7-1,2.54l-2.51,5.88c-.32.64-.64,1.25-1,1.84s-.64,1.15-1,1.71a3.11,3.11,0,0,1-1.24.09l-1.42-.18a25.69,25.69,0,0,1-1.21-2.76c-.35-.94-.7-1.86-1.06-2.77l-2.35-5.33L196.27,221a16,16,0,0,0-.29,3.36c0,1.17,0,2.33,0,3.48s0,2.35,0,3.49-.05,2.3-.13,3.48c0,1,0,1.91,0,2.84a11,11,0,0,1-.3,2.7l-3.72,0c-.79,0-1.56.05-2.31,0a13.75,13.75,0,0,1-2.21-.27l-.07-8,.16-7,0-8.07.25-7.56.18-.5Z" transform="translate(-0.67)"/>
                
                <path className="MemberLogoGreen" d="M238.15.53s-1.41-1.5-1.75.54l-3.75,22.31a4.23,4.23,0,0,1-2.21,2.91L209.85,35.9s-1.87.87,0,1.81l20.06,10.34A4.17,4.17,0,0,1,232,51l2.69,22.55s.24,2,1.72.61l16.31-15.86a4.22,4.22,0,0,1,3.5-1l22.36,4.42s2,.4,1.1-1.44L269.32,39.85a4.17,4.17,0,0,1,.06-3.65L280.3,16.49s1-1.81-1-1.52l-22.19,3.22A4.27,4.27,0,0,1,253.62,17Z" transform="translate(-0.67)"/>
                
            </svg>

        );

    }

    render() {

        const { className, height } = this.props;
        const CA = [ "MemberLogo" ];

        if ( className ) {
            
            CA.push( className );

        }

        const CS = CA.join( " " );

        return (

            <div className={ CS } style={{ height }}>

                { this.Svg() }
            
            </div>

        );

    }

}

MemberLogo.propTypes = {

    className: PropTypes.string,
    height: PropTypes.number

};

MemberLogo.defaultProps = {

    className: "",
    height: 210

}

export default MemberLogo;