
/*!
 *  Checkbox field.
 *
 *  @prop boolean|integer checked - Whether the field is checked.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field is disabled.
 *  @prop string id - Field ID.
 *  @prop string label - Field label. Overrides children.
 *  @prop function onChange - Callback function.
 *  @prop string title - The checkbox title attribute.
 *  @prop JSX [ ...children ] - Field content.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./checkbox.scss";

class Checkbox extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            checked: false

        };

    }

    /**
     * Set initial value.
     * 
     * @return void
     */

    componentDidMount() {

        const { checked } = this.props;

        this.setState( { checked } );

    }

    /**
     * Update value.
     * 
     * @return void
     */

    componentWillReceiveProps( nextProps ) {

        const { checked } = nextProps;

        if ( checked !== this.props.checked ) {
            
            this.setState( { checked } );

        }

    }

    /**
     * Toggle value when clicked.
     * 
     * @param object e - Click event.
     * 
     * @return void
     */

    OnClick = (e) => {

        const { disabled, id, onChange } = this.props;
        let { checked } = this.state;

        if ( disabled ) {

            return;

        }

        checked = !checked;

        this.setState( { checked } );

        onChange( e, checked, id );

    }

    /**
     * Reset to inital state.
     * 
     * @return void
     */

    Reset = () => {

        const { checked } = this.props;

        this.setState( { checked } );

    }

    render() {

        const { children, className, disabled, label, title } = this.props;
        const { checked } = this.state;
        const CA = [ "Checkbox" ];

        // Behaviour and appearance changes depending on if props.label is set.
        if ( className ) CA.push( className );
        if ( checked ) CA.push( "Checked" );
        if ( disabled ) CA.push( "Disabled" );
        if ( label ) CA.push( "HasLabel" );
        else CA.push( "NoLabel" );

        const CS = CA.join( " " );
        const Label = label || children;

        return (
            
            <div className={ CS }>

                <div
                
                    className="Input" onClick={ this.OnClick }
                    onMouseDown={ e => e.stopPropagation() }
                    title={ title || label }
                    
                />

                { Label ? <div
                
                    className="CheckboxLabel"
                    onClick={ label ? this.OnClick : null }
                    
                >
                
                    { Label }
                
                </div> : "" }

            </div>
            
        );

    }

}

Checkbox.propTypes = {

    checked: PropTypes.oneOfType( [ PropTypes.bool, PropTypes.number ] ),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    label: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    onChange: PropTypes.func,
    title: PropTypes.string

};

Checkbox.defaultProps = {

    checked: false,
    className: "",
    disabled: false,
    id: "",
    label: "",
    onChange: () => {},
    title: ""

};

export default Checkbox;