
/*!
 *  Load and display an image resource.
 *
 *  @prop string alt - Image alt tag.
 *  @prop boolean autoAdjust - Whether the containing div should auto-adjust to fit the image.
 *  @prop string className - Append a class name.
 *  @prop function onLoad - OnLoad callback. (src, width, height).
 *  @prop function onError - OnError callback. (src).
 *  @prop string src - Image source URL.
 *  @prop string src2x - Double resolution image source URL.
 *  @prop object style - Image inline styles.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./loadimage.scss";

class LoadImage extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            height: 0,
            loaded: false,
            width: 0

        };

        this.Mounted = false;
        this.Loading = false;
        this.Src = "";

    }

    /**
     * Load the image on mount.
     *
     * @return void
     */

    componentDidMount() {

        this.Mounted = true;

        const { src, src2x } = this.props;
        
        const PR = window.devicePixelRatio;
        const Src = PR > 1 && src2x ? src2x : src;

        this.Load( Src );

    }

    /**
     * Reload the image when its' source changes.
     *
     * @return void
     */

    componentWillReceiveProps( nextProps ) {

        const { src, src2x } = nextProps;

        if ( src !== this.props.src || src2x !== this.props.src2x ) {

            this.setState( { loaded: false } );

            const PR = window.devicePixelRatio;
            const Src = PR > 1 && src2x ? src2x : src;

            this.Load( Src );

        }

    }

    /**
     * Register unmount.
     *  
     * @return void
     */

    componentWillUnmount() {

        this.Mounted = false;

    }

    /**
     *   Load an image source.
     * 
     *   @param string src - Image source URL or image name in Media/Photos
     *
     *   @return void
     */

    Load = ( src ) => {

        if ( !src ) {

            return;

        }

        const { onError, onLoad } = this.props;
        this.Loading = true;

        const Img = new Image();

        Img.onload = () => {

            if ( !this.Mounted ) {

                return;

            }

            const { width, height } = Img;

            this.setState( {
                
                height,
                loaded: true,
                width
                
            } );

            this.Loading = false;

            onLoad( src, width, height );

        };

        Img.onerror = () => {

            if ( !this.Mounted ) {
                
                return;

            }

            this.setState( { error: true } );

            this.Loading = false;

            onError( src );

        };

        Img.src = this.Src = src;

    }

    render() {

        const { autoAdjust, className, style } = this.props;
        const { width, height } = this.state;

        const CA = [ "LoadImage" ];

        if ( className ) {

            CA.push( className );

        }

        if ( this.state.loaded ) {

            CA.push( "Loaded" );

        }

        const CS = CA.join( " " );
        const Style = Object.assign( {}, style );

        Style.backgroundImage = `url(${this.Src})`;

        if ( autoAdjust ) {

            Style.paddingTop = height / width * 100 + "%"

        }
        
        return (

            <div className={ CS } style={ Style } />

        );

    }

}

LoadImage.propTypes = {

    alt: PropTypes.string,
    autoAdjust: PropTypes.bool,
    className: PropTypes.string,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    src: PropTypes.oneOfType( [ PropTypes.string, PropTypes.bool ] ),
    src2x: PropTypes.oneOfType( [ PropTypes.string, PropTypes.bool ] ),
    style: PropTypes.object

};

LoadImage.defaultProps = {

    alt: "",
    autoAdjust: false,
    className: "",
    onLoad: () => {},
    onError: () => {},
    src: "",
    src2x: "",
    style: {}

};

export default LoadImage;