
/*!
 *  Family selection
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./family.scss";

import Coop from "Classes/Coop";
import FamilySelector from "Components/UI/FamilySelector";

class Family extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            family: 0

        };
        
    }

    componentDidMount() {

        this.setState( {

            family: Coop.GetFamily()

        } );

        Coop.Listen( "family", this.OnFamily );

    }

    componentWillUnmount() {

        Coop.Remove( "family", this.OnFamily );

    }

    OnFamily = ( family ) => {

        this.setState( { family } );

    }

    SetFamily = ( e, index ) => {

        Coop.SetFamily( index );

    }

    render() {

        const { family } = this.state;

        return (

            <div className="Family">

                <div className="FamilyContent">

                    <h2>Välj familj och börja räkna.</h2>

                    <FamilySelector
                    
                        onClick={ this.SetFamily }
                        selectedIndex={ family }
                    
                    />

                </div>

            </div>

        );

    }

}

export default Family;