
/*!
 *  Input fields.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./input.scss";

import Coop from "Classes/Coop";
import { NiceNumber } from "Functions";

import Card1x from "./card.png";
import Card2x from "./card@2x.png";

import Checkbox from "Components/UI/Checkbox";
import LoadImage from "Components/Layout/LoadImage";
import RangeSlider from "Components/UI/RangeSlider";

class Input extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

           food: 0,
           leasure: 0,
           mastercard: false,
           shopping: 0,
           travel: 0,

        };
        
    }

    componentDidMount() {

        this.setState( {

            food: Coop.GetAmountFood(),
            leasure: Coop.GetAmountLeasure(),
            mastercard: Coop.GetMastercard(),
            shopping: Coop.GetAmountShopping(),
            travel: Coop.GetAmountTravel()

        } );

        Coop.Listen( "amount_food", this.OnFood );
        Coop.Listen( "amount_leasure", this.OnLeasure );
        Coop.Listen( "amount_shopping", this.OnShopping );
        Coop.Listen( "amount_travel", this.OnTravel );
        Coop.Listen( "mastercard", this.OnMastercard );

    }

    componentWillUnmount() {

        Coop.Remove( "amount_food", this.OnFood );
        Coop.Remove( "amount_leasure", this.OnLeasure );
        Coop.Remove( "amount_shopping", this.OnShopping );
        Coop.Remove( "amount_travel", this.OnTravel );
        Coop.Remove( "mastercard", this.OnMastercard );

    }

    OnFood = ( food ) => {

        this.setState( { food } );

    }

    OnLeasure = ( leasure ) => {

        this.setState( { leasure } );

    }

    OnMastercard = ( mastercard ) => {

        this.setState( { mastercard } );

    }

    OnShopping = ( shopping ) => {

        this.setState( { shopping } );

    }

    OnTravel = ( travel ) => {

        this.setState( { travel } );

    }

    SetFood = ( e, food ) => {

        Coop.SetAmountFood( food );

    }

    SetLeasure = ( e, leasure ) => {

        Coop.SetAmountLeasure( leasure );

    }

    SetMastercard = ( e, mastercard ) => {

        Coop.SetMastercard( mastercard );

    }

    SetShopping = ( e, shopping ) => {

        Coop.SetAmountShopping( shopping );

    }

    SetTravel = ( e, travel ) => {

        Coop.SetAmountTravel( travel );

    }

    render() {

        const { food, leasure, mastercard, shopping, travel } = this.state;
        const Sliders = [

            [ "Mat på Coop", food, this.SetFood ],
            [ "Shopping", shopping, this.SetShopping ],
            [ "Resor & Hotell", travel, this.SetTravel ],
            [ "Hem & Fritid", leasure, this.SetLeasure ]

        ];

        return (

            <div className="Input" id="inputs">

                <div className="InputContent">

                    <div className="InputSliders">

                        { Sliders.map( ( [ label, value, onChange ], index ) => (

                            <div className="InputSliderContainer" key={ label + index }>

                                <div className="InputSliderLabel">{ label }*</div>

                                <div className="InputSliderAmount">

                                    <div className="InputSliderAmountNumber">{ NiceNumber( value ) }</div>
                                    <div className="InputSliderAmountUnit">kr/mån</div>

                                </div>

                                <RangeSlider
                                
                                    max={ 10000 }
                                    onChange={ onChange }
                                    value={ value }
                                
                                />

                            </div>

                        ) ) }

                    </div>

                    <div className="InputMastercard">

                        <div className="InputMastercardCardContainer">

                            <LoadImage
                            
                                autoAdjust={ true }
                                className="InputMastercardCard"
                                src={ Card1x }
                                src2x={ Card2x }
                            
                            />

                        </div>

                        <p>Se hur mycket extra poäng du hade fått med ett Coop Mastercard.</p>

                        <Checkbox
                        
                            checked={ mastercard }
                            onChange={ this.SetMastercard }
                        
                        />

                        <a rel="noopener noreferrer" target="_blank" href="https://coop.se/mastercard">Läs mer och ansök om Coop Mastercard här.</a>

                    </div>

                </div>

            </div>

        );

    }

}

export default Input;