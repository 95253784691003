
/*!
 *  Points per krona
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./points.scss";

import Hand1x from "./hand.png";
import Hand2x from "./hand@2x.png";
import LoadImage from "Components/Layout/LoadImage";

class Points extends React.Component {

    constructor( props ) {

        super( props );

        this.RandomInterval = false;
        this.RandomMs = 3000;

        this.state = {

            decimals: 0,
            points: 0,
            previous: 0

        };

    }

    componentDidMount() {

        const { points, random } = this.props;

        if ( random && !this.RandomInterval ) {

            this.SetRandom();
            this.RandomInterval = setInterval( this.SetRandom, this.RandomMs );

        }

        else if ( !random ) {

            clearInterval( this.RandomInterval );

            const Decimals = ( String( points ).split( "." )[1] || "" ).length;

            this.setState( {

                decimals: Decimals,
                points
                
            } );

        }

    }

    componentWillReceiveProps( nextProps ) {

        const { points, random } = nextProps;

        if ( random && !this.RandomInterval ) {

            this.SetRandom();
            this.RandomInterval = setInterval( this.SetRandom, this.RandomMs );

        }

        else if ( !random ) {

            clearInterval( this.RandomInterval );

            if ( points !== this.state.points ) {

                const Decimals = ( String( points ).split( "." )[1] || "" ).length;

                this.setState( {

                    decimals: Decimals,
                    points,
                    previous: this.state.points
                    
                } );

            }

        }

    }

    componentWillUnmount() {

        clearInterval( this.RandomInterval );

    }

    SetRandom = ( props ) => {

        const { random } = props || this.props;

        if ( !random ) {

            return;

        }

        const Index = Math.floor( Math.random() * random.length );
        const Points = random[ Index ];
        const Decimals = ( String( Points ).split( "." )[1] || "" ).length;

        if ( Points === this.state.points ) {

            return;

        }

        this.setState( {

            decimals: Decimals,
            points: Points,
            previous: this.state.points
            
        } );

    }

    render() {

        const { className, random, unit } = this.props;
        const { decimals, points, previous } = this.state;
        const CA = [ "Points", `Decimals${decimals}` ];

        if ( className ) {
            
            CA.push( className );

        }

        if ( random ) {

            CA.push( "Random" );

        }

        const CS = CA.join( " " );
        const Direction = previous > points ? "Down" : "Up";

        return (

            <div className={ CS }>

                <LoadImage
                
                    className="PointsHand"
                    src={ Hand1x }
                    src2x={ Hand2x }
                
                />

                <div className="PointsCounterContainer">

                    <div className="PointsCounter">

                        <div className="PointsCounterNumberContainer">

                            { points !== previous ? <div
                            
                                className={ `PointsCounterNumber PointsCounterNumberPrevious ${Direction}` }
                                key={ "previous" + previous }
                                
                            >

                                { previous }
                                    
                            </div> : "" }

                            <div
                            
                                className={ `PointsCounterNumber PointsCounterNumberCurrent ${Direction}` }
                                key={ "current" + points }
                                
                            >

                                { points }
                                    
                            </div>

                        </div>

                        <div className="PointsCounterUnit">{ unit }</div>
                        
                    </div>

                </div>
            
            </div>

        );

    }

}

Points.propTypes = {

    className: PropTypes.string,
    points: PropTypes.number,
    random: PropTypes.oneOfType( [ PropTypes.array, PropTypes.bool ] ),
    unit: PropTypes.string

};

Points.defaultProps = {

    className: "",
    points: 0,
    random: false,
    unit: "poäng/krona"

}

export default Points;