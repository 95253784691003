
/*!
 *  Page footer.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./footer.scss";

import LoadImage from "Components/Layout/LoadImage";
import MemberLogo from "Components/Layout/MemberLogo";

import Partners1x from "./partners3.png";
import Partners2x from "./partners3@2x.png";

class Footer extends React.Component {

    render() {

        return (

            <div className="Footer">

                <div className="FooterContent">

                    <MemberLogo className="FooterLogo" />

                    <div className="FooterLabel">Några utvalda partners</div>

                    <div className="FooterLoaf">

                        <LoadImage
                        
                            autoAdjust={ true }
                            className="FooterLoafPartners"
                            src={ Partners1x }
                            src2x={ Partners2x }
                        
                        />

                    </div>

                </div>

            </div>

        );

    }

}

export default Footer;