
import React from "react";
import "core-js";
import "./core.scss";

// Google Analytics for tracking.
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

import Button from "Components/UI/Button";
import Family from "Components/View/Family";
import Footer from "Components/View/Footer";
import Header from "Components/View/Header";
import Input from "Components/View/Input";
import Intro from "Components/View/Intro";
import Total from "Components/View/Total";

class Core extends React.Component {

    constructor( props ) {

        super( props );

        this.GA = "UA-153589889-1";
        this.GTM = "GTM-5L8XJ5D";

        this.Touch = "ontouchstart" in window;

        if ( this.GA ) {

            ReactGA.initialize( this.GA );
            ReactGA.pageview( "/" );

        }

        if ( this.GTM ) {

            TagManager.initialize( { gtmId: this.GTM } );

        }

        this.state = {

            error: false,
            height: 0,
            landscape: false,
            ready: false,
            width: 0

        };

    }

    render() {

        const CA = [ "Core" ];

        if ( this.Touch ) {

            CA.push( "Touch" );

        }

        else {

            CA.push( "NoTouch" );

        }

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                <Header />
                <Intro />
                <Family />
                <Input />
                <Total />

                <div className="PartnersButtonContainer">

                    <Button
                    
                        className="PartnersButton"
                        href="https://coop.se/tjanapoang"
                        target="_blank"
                        label="Till våra partners"
                    
                    />

                </div>

                <Footer />

            </div>

        );

    }

}

export default Core;