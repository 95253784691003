
/*!
 *  Display the total amount of points per month.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./total.scss";

import Coop from "Classes/Coop";
import { NiceNumber } from "Functions";

import Button from "Components/UI/Button";
import Collapsable from "Components/Layout/Collapsable";

class Total extends React.Component {

    constructor( props ) {

        super( props );

        this.BreakingPoint = 1000;
        this.Fixed = false;

        this.state = {

            collapsed: true,
            fixed: false,
            total: 0,
            show: true

        };
        
    }

    componentDidMount() {

        this.setState( {

            total: Coop.GetTotal()

        } );

        Coop.Listen( "total", this.OnTotal );
        window.addEventListener( "resize", this.OnResize );

        this.OnResize();

    }

    componentWillUnmount() {

        Coop.Remove( "total", this.OnTotal );
        window.removeEventListener( "resize", this.OnResize );

        if ( this.Fixed ) {

            window.removeEventListener( "scroll", this.OnScroll );

        }

    }

    OnResize = () => {

        const Width = window.outerWidth;

        if ( Width <= this.BreakingPoint && !this.Fixed ) {

            this.Fixed = true;
            window.addEventListener( "scroll", this.OnScroll );

        }

        else if ( Width > this.BreakingPoint && this.Fixed ) {

            this.Fixed = false;
            window.removeEventListener( "scroll", this.OnScroll );

            this.setState( {
                
                fixed: false,
                show: true
                
            } );

        }

        if ( this.Fixed ) {

            this.OnScroll();

        }

    }

    OnScroll = () => {

        const Inputs = document.getElementById( "inputs" );

        if ( !Inputs ) {

            return;

        }

        const { fixed, show } = this.state;
        const { box } = this.refs;
        const { top } = box.parentNode.getBoundingClientRect();
        const { top: inputs } = Inputs.getBoundingClientRect();
        const Limit = inputs - window.innerHeight;
        const Offset = top + box.offsetHeight - window.innerHeight;
        const State = {};
        
        if ( Offset > 0 && !fixed ) State.fixed = true;
        else if ( Offset <= 0 && fixed ) State.fixed = false;

        if ( Limit <= 0 && !show ) State.show = true;
        else if ( Limit > 0 && show ) State.show = false;

        this.setState( State );

    }

    OnToggleExpand = () => {

        const { collapsed } = this.state;

        this.setState( {

            collapsed: !collapsed

        } );

    }

    OnTotal = ( total ) => {

        this.setState( { total } );

    }

    render() {

        const { collapsed, fixed, show, total } = this.state;
        const CA = [ "Total" ];

        if ( !collapsed ) {

            CA.push( "Expanded" );

        }

        if ( fixed ) {

            CA.push( "Fixed" );

        }

        if ( show ) {

            CA.push( "Show" );

        }

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                <div className="TotalContent">

                    <div className="TotalContentWrapper">

                        <div className="TotalBox" ref="box">

                            <div className="TotalBoxLabel">Intjänade poäng per år</div>

                            <div className="TotalBoxAmount">

                                Totalt <span className="TotalBoxAmountNumber">{ NiceNumber( total ) } poäng</span>

                            </div>

                            <Collapsable
                            
                                className="TotalBoxButtonContainer"
                                collapsed={ !fixed }
                                duration={ .25 }
                                
                            >

                                <Button
                    
                                    className="TotalBoxButton"
                                    href="https://coop.se/tjanapoang"
                                    target="_blank"
                                    label="Till våra partners"
                                
                                />

                            </Collapsable>

                        </div>

                        <div className="TotalAbout" onClick={ this.OnToggleExpand }>*Läs mer om hur uträkningen är gjord här</div>

                        <Collapsable
                        
                            className="TotalAboutText"
                            collapsed={ collapsed }
                        
                        >
                        
                            <h3>Startvärden</h3>
                            
                            <p>Startvärdena för respektive familj är baserad på den disponibla inkomsten per capita och uppdelad utifrån hur hushållens konsumtion, efter kategori, såg ut under 2018 enligt SCB genom ekonomifakta.se.</p>

                            <p className="TotalAboutTextSources">

                                <i>Källor för uträkningen:</i>
                                <a rel="noopener noreferrer" target="_blank" href="https://www.ekonomifakta.se/fakta/ekonomi/hushallens-ekonomi/hushallens-konsumtionsutgifter-efter-andamal/?graph=/15994/all/all/">Ekonomifakta.se</a>
                                <a rel="noopener noreferrer" target="_blank" href="https://www.cuponation.se/insikt/konsumtion-sverige">Cuponation</a>
                                <a rel="noopener noreferrer" target="_blank" href="https://www.swedbank.se/privat/vara-kundpaket/nyckelkund/vad-kostar-det-att-leva.html">Swedbank</a>

                            </p>

                            <h3>Reglagens uträkning</h3>

                            <p>Reglagen är beräknade utifrån antalet poäng du får hos olika intjäningspartners i respektive kategori. Du finner alla partners <a rel="noopener noreferrer" target="_blank" href="https://coop.se/tjanapoang">här</a>.</p>

                            <h4>*Mat på Coop</h4>

                            <p>Poängen är uträknade utifrån Coops poängtrappan. Läs mer om den <a rel="noopener noreferrer" target="_blank" href="https://www.coop.se/medlem/5-procent/">här</a>.</p>

                            <h4>*Shopping</h4>

                            <p>Partners ger mellan 2-6 poäng/krona – reglagen ger 3 poäng/krona.</p>

                            <h4>*Resor/semester/hotell</h4>

                            <p>Partners ger mellan 1-4 poäng/krona – reglagen ger 1 poäng/krona.</p>

                            <h4>*Hem &amp; fritid</h4>

                            <p>Partners ger mellan 2-4 poäng/krona – reglagen ger 3 poäng/krona.</p>

                            <div className="TotalAboutHide" onClick={ this.OnToggleExpand }>Dölj</div>
                        
                        </Collapsable>

                    </div>

                </div>

            </div>

        );

    }

}

export default Total;