
/*!
 *  Button.
 *
 *  @prop string className - Append a class name.
 *  @prop string href - Optional href.
 *  @prop string label - Button label.
 *  @prop function onClick - Callback when the button is clicked.
 *  @prop string target - Optional href target.
 *  @prop string title - The button title attribute.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./button.scss";

class Button extends React.Component {

    render() {

        const { className, href, label, onClick, target, title } = this.props;
        const CA = [ "Button" ];

        if ( className ) {

            CA.push( className );

        }

        const CS = CA.join( " " );
        const Rel = target === "_blank" ? "noopener nofollow" : "";

        return href ? (

            <a
            
                className={ CS }
                href={ href }
                onClick={ onClick }
                rel={ Rel }
                target={ target }
                title={ title || label }
                
            >{ label }</a>

        ) : (

            <span
            
                className={ CS }
                onClick={ onClick }
                title={ title || label }
                
            >{ label }</span>

        );

    }

}

Button.propTypes = {

    className: PropTypes.string,
    href: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    target: PropTypes.string,
    title: PropTypes.string

};

Button.defaultProps = {

    className: "",
    href: "",
    label: "",
    onClick: () => {},
    target: "",
    title: ""

};

export default Button;