
/*!
 *  Page header.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import "./header.scss";

import Logo from "Components/Layout/Logo";

class Header extends React.Component {

    render() {

        return (

            <div className="Header">

                <div className="HeaderContent">

                    <Logo />

                    { " " }

                    <a href="https://coop.se">Tillbaka till coop.se</a>

                </div>

            </div>

        );

    }

}

export default Header;