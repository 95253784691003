
/*!
 *  Select type of family.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./familyselector.scss";

import Family1x from "./family@2x.png";
import Family2x from "./family@4x.png";
import Pair1x from "./pair@2x.png";
import Pair2x from "./pair@4x.png";
import SingleParent1x from "./singleparent@2x.png";
import SingleParent2x from "./singleparent@4x.png";
import LoadImage from "Components/Layout/LoadImage";

class FamilySelector extends React.Component {

    render() {

        const { className, onClick, selectedIndex } = this.props;
        const CA = [ "FamilySelector" ];

        if ( className ) {
            
            CA.push( className );

        }

        const CS = CA.join( " " );
        const Families = [

            [ "Två vuxna", Pair1x, Pair2x ],
            [ "En vuxen + ett barn", SingleParent1x, SingleParent2x ],
            [ "Två vuxna + två barn", Family1x, Family2x ]

        ];

        return (

            <div className={ CS }>

                { Families.map( ( [ label, image1x, image2x ], index ) => (

                    <div
                    
                        className={ index === selectedIndex ? "FamilySelectorItem Selected" : "FamilySelectorItem" }
                        key={ index }
                        onClick={ e => onClick( e, index ) }
                        
                    >

                        <LoadImage
                        
                            className="FamilySelectorItemImage"
                            src={ image1x }
                            src2x={ image2x }
                        
                        />

                        <div className="FamilySelectorItemLabel">{ label }</div>

                    </div>

                ) ) }
            
            </div>

        );

    }

}

FamilySelector.propTypes = {

    className: PropTypes.string,
    onClick: PropTypes.func,
    selectedIndex: PropTypes.number

};

FamilySelector.defaultProps = {

    className: "",
    onClick: () => {},
    selectedIndex: 0

}

export default FamilySelector;