
/*!
 *  Site logo.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./logo.scss";

class Logo extends React.Component {

    Svg = () => {

        return (

            <svg viewBox="-236 244 369 107.3">
                
                <path d="M80.2 309.6c-14.3 0-20.1-10.8-20.1-22.8 0-12.2 5.9-23 20.1-23 14.3 0 20.1 10.8 20.1 23 .1 12-5.8 22.8-20.1 22.8m-98.6 0c-14.3 0-20.1-10.8-20.1-22.8 0-12.2 5.9-23 20.1-23 14.3 0 20.1 10.8 20.1 23 0 12-5.8 22.8-20.1 22.8m-93.1 0c-14.3 0-20.1-10.8-20.1-22.8 0-12.2 5.8-23 20.1-23s20.1 10.8 20.1 23c0 12-5.8 22.8-20.1 22.8M80.2 244c-23.4 0-42.4 9.8-49.3 27.3C24 253.8 5 244-18.4 244c-20.8 0-38.1 7.7-46.6 21.7-8.4-13.9-25.7-21.7-46.5-21.7-29.6 0-52.2 15.7-52.2 42.7 0 6.8 1.4 12.9 4.1 18.2-.3.1-.7.3-1 .4-1.3.5-2.8 1-4.4 1.5-4.1 1.1-8.7 1.7-13.3 1.7-13.4 0-25.5-8.4-25.5-21.4 0-14.1 10.3-22.5 23.7-22.5 5.1.1 9.1.4 13.1 1.8 0 0 4.6-10.1 12.4-15.9-1.5-.7-3.1-1.4-4.7-2.1-7-3-15.2-4.4-24.4-4.4-29.6 0-52.1 15.7-52.1 42.7 0 26.9 22.5 42.6 52.1 42.6 7.8 0 16.5-1.1 24-3.2 2.8-.7 9.4-2.9 14.2-5.7 9 5.8 20.8 8.9 34 8.9 20.8 0 38.1-7.7 46.6-21.7 8.5 13.9 25.8 21.7 46.6 21.7s38-7.7 46.5-21.6V351H60v-24.5c6.2 1.8 13 2.8 20.3 2.8 29.6 0 52.1-15.6 52.1-42.6S109.8 244 80.2 244" />
                
            </svg>

        );

    }

    render() {

        const { className } = this.props;
        const CA = [ "Logo" ];

        if ( className ) {
            
            CA.push( className );

        }

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                { this.Svg() }
            
            </div>

        );

    }

}

Logo.propTypes = {

    className: PropTypes.string

};

Logo.defaultProps = {

    className: ""

}

export default Logo;