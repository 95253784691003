
class Coop {

    constructor() {

        this.Listeners = {};
        this.StorageEnabled = false;
        this.Vars = {};

        this.Family   = 0;
        this.Families = [

            [ 4670, 7750, 2630, 4230 ],
            [ 3310, 4730, 1320, 2560 ],
            [ 6920, 9490, 2820, 5370 ]

        ];

        const [ Food, Shopping, Travel, Leasure ] = this.Families[ this.Family ];

        this.AmountFood     = Food;
        this.AmountShopping = Shopping;
        this.AmountTravel   = Travel;
        this.AmountLeasure  = Leasure;
        this.Mastercard     = false;
        this.PointsPerUnit  = 0;
        this.Stages         = [ 0, 0.5, 1, 1, 1, 1 ];
        this.StepSize       = 100;
        this.Total          = 0;

        // Trigger an event when fonts load to update section masks etc.
        if ( document.fonts ) {

            document.fonts.onloadingdone = this.OnLoadFont;

        }

        this.Permissions();
        this.Update();

    }

    /**
     * Save or Load a value from the clients browser.
     * TODO: Fallback when local/session storage is unavailable.
     *
     * @param string key - The value key.
     * @param mixed value - Optional. Set value.
     * 
     * @return mixed - The setting value.
     */

    Client = ( key, value = null ) => {

        if ( this.StorageEnabled ) {

            return this.Session( key, value ) || this.Storage( key, value );

        }

        return undefined;

    }

    /**
     * Remove a value from the clients browser.
     * TODO: Fallback when local/session storage is unavailable.
     *
     * @param string key - The value key.
     * 
     * @return void
     */

    ClientRemove = ( key ) => {

        if ( this.StorageEnabled ) {

            this.SessionRemove( key );
            this.StorageRemove( key );

        }

    }

    /**
     * Get all event listeners for an event.
     *
     * @param string event - The event name
     * 
     * @return array - An array of listeners.
     */

    Get = ( event ) => {

        if ( this.Listeners[ event ] === undefined ) {

            this.Listeners[ event ] = [];

        }

        return this.Listeners[ event ];

    }

    GetAmountFood = () => {

        return this.AmountFood;

    }

    GetAmountShopping = () => {

        return this.AmountShopping;

    }

    GetAmountTravel = () => {

        return this.AmountTravel;

    }

    GetAmountLeasure = () => {

        return this.AmountLeasure;

    }

    GetFamily = () => {

        return this.Family;

    }

    GetMastercard = () => {

        return this.Mastercard;

    }

    GetPointsPerUnit = () => {

        return this.PointsPerUnit;

    }

    GetTotal = () => {

        return this.Total;

    }

    /**
     * Increase and return the value of a global variable.
     *
     * @param string key - The value key.
     * 
     * @return mixed - The value.
     */

    Increase = ( key ) => {

        let Value = this.Vars[ key ];

        if ( Value === undefined ) {

            return this.Vars[ key ] = 0;

        }

        if ( typeof Value !== "number" ) {

            return Value;

        }

        return this.Vars[ key ] = Value + 1;

    }

    /**
     * Add an event listener
     *
     * @param string event - The event name
     * @param function callback - The callback function
     * 
     * @return void
     */

    Listen = ( event, callback ) => {

        if ( typeof callback !== "function" ) {

            return;

        }

        const Listeners = this.Get( event );
        const Index = Listeners.indexOf( callback );

        if ( Index < 0 ) {

            Listeners.push( callback );

        }

    }

    /**
     * Callback when a font loads.
     * 
     * @return void
     */

    OnLoadFont = (e) => {

        this.Trigger( "font" );

    }

    /**
     * Check if local/ession storage is available.
     *
     * @return boolean - Whether local/session storage is available.
     */

    Permissions = () => {

        try {

            localStorage.setItem( "test", "test" );
            localStorage.removeItem( "test" );
            sessionStorage.setItem( "test", "test" );
            sessionStorage.removeItem( "test" );

            return this.StorageEnabled = true;

        }

        catch (e) {

            return this.StorageEnabled = false;

        }

    }

    /**
     * Remove an event listener
     *
     * @param string event - The event name
     * @param function callback - The callback function
     * 
     * @return void
     */

    Remove = ( event, callback ) => {

        const Listeners = this.Get( event );
        const Index = Listeners.indexOf( callback );

        if ( Index < 0 ) {

            return;

        }

        Listeners.splice( Index, 1 );

    }

    /**
     * Save or Load a value from the clients session storage.
     *
     * @param string key - The value key.
     * @param mixed value - Optional. Set value.
     * 
     * @return mixed - The setting value.
     */
    
    Session = ( key, value = null ) => {

        if ( !this.StorageEnabled ) {

            return undefined;

        }

        if ( value !== null ) {

            sessionStorage.setItem( key, value );

        }

        else {

            value = sessionStorage.getItem( key );

        }

        switch ( value ) {

            case "false": return false;
            case "true": return true;
            case null: return undefined;
            default: return value;

        }

    }

    /**
     * Remove a value from the clients session storage.
     *
     * @param string key - The value key.
     * 
     * @return void
     */
    
    SessionRemove = ( key ) => {

        if ( !this.StorageEnabled ) {

            return undefined;

        }

        sessionStorage.removeItem( key );

    }

    SetAmountFood = ( food ) => {

        const Food = Math.round( food / this.StepSize ) * this.StepSize;

        this.Trigger( "amount_food", this.AmountFood = Food );
        this.Update();

    }

    SetAmountShopping = ( shopping ) => {

        const Shopping = Math.round( shopping / this.StepSize ) * this.StepSize;

        this.Trigger( "amount_shopping", this.AmountShopping = Shopping );
        this.Update();

    }

    SetAmountTravel = ( travel ) => {

        const Travel = Math.round( travel / this.StepSize ) * this.StepSize;

        this.Trigger( "amount_travel", this.AmountTravel = Travel );
        this.Update();

    }

    SetAmountLeasure = ( leasure ) => {

        const Leasure = Math.round( leasure / this.StepSize ) * this.StepSize;

        this.Trigger( "amount_leasure", this.AmountLeasure = Leasure );
        this.Update();

    }

    SetFamily = ( family ) => {

        const [ Food, Shopping, Travel, Leasure ] = this.Families[ family ] || this.Families[ family = 0 ];

        this.Trigger( "amount_food", this.AmountFood = Food );
        this.Trigger( "amount_shopping", this.AmountShopping = Shopping );
        this.Trigger( "amount_travel", this.AmountTravel = Travel );
        this.Trigger( "amount_leasure", this.AmountLeasure = Leasure );
        this.Trigger( "family", this.Family = family );
        this.Update();

    }

    SetMastercard = ( mastercard ) => {

        this.Trigger( "mastercard", this.Mastercard = mastercard );
        this.Update();

    }
    
    /**
     * Save or Load a value from the clients local storage.
     *
     * @param string key - The value key.
     * @param mixed value - Optional. Set value.
     * 
     * @return mixed - The setting value.
     */
    
    Storage = ( key, value = null ) => {

        if ( !this.StorageEnabled ) {

            return undefined;

        }

        if ( value !== null ) {

            localStorage.setItem( key, value );

        }

        else {

            value = localStorage.getItem( key );

        }

        switch ( value ) {

            case "false": return false;
            case "true": return true;
            case null: return undefined;
            default: return value;

        }

    }

    /**
     * Remove a value from the clients local storage.
     *
     * @param string key - The value key.
     * 
     * @return void
     */
    
    StorageRemove = ( key ) => {

        if ( !this.StorageEnabled ) {

            return undefined;

        }

        localStorage.removeItem( key );

    }

    /**
     * Toggle a global variable between true/false.
     *
     * @param string key - The value key.
     * 
     * @return mixed - The value.
     */

    Toggle = ( key ) => {

        const Value = !this.Vars[ key ];

        return this.Var( key, Value );

    }

    /**
     * Trigger an event
     *
     * @param string event - The event name
     * @param mixed data1 - Optional. Data to send to the event listeners.
     * @param mixed data2 - Optional. Data to send to the event listeners.
     * @param mixed data3 - Optional. Data to send to the event listeners.
     * 
     * @return void
     */

    Trigger = ( event, data1, data2, data3 ) => {

        const Listeners = this.Get( event );

        Listeners.forEach( callback => {

            callback( data1, data2, data3 );

        } );

    }

    Update = () => {

        const AmountFood = this.GetAmountFood();
        const AmountLeasure = this.GetAmountLeasure();
        const AmountShopping = this.GetAmountShopping();
        const AmountTravel = this.GetAmountTravel();
        const Mastercard = this.GetMastercard();
        
        const Add = Mastercard ? .5 : 0;
        let Total = 0;

        Total += AmountLeasure * ( 3 + Add );
        Total += AmountShopping * ( 3 + Add );
        Total += AmountTravel * ( 1 + Add );

        let Remaining = AmountFood;
        let Stage = 0;
        let Segment;

        let PPU = .5 + Add;

        while ( Remaining ) {

            if ( this.Stages[ Stage ] === undefined ) {

                Total += Remaining * PPU;
                break;

            }

            PPU += this.Stages[ Stage ];
            Segment = Math.min( Remaining, 1000 );

            Total += Segment * PPU;

            Remaining -= Segment;
            Stage++;

        }

        this.Trigger( "total", this.Total = Total * 12 );
        this.Trigger( "points_per_unit", this.PointsPerUnit = PPU );

    }

    /**
     * Set or get the value of a global variable.
     *
     * @param string key - The value key.
     * @param mixed value - Optional. Set value.
     * 
     * @return mixed - The value.
     */

    Var = ( key, value ) => {

        if ( value === undefined || value === this.Vars[ key ] ) {

            return this.Vars[ key ];

        }

        this.Trigger( "var-" + key, value, this.Vars[ key ] );

        return this.Vars[ key ] = value;

    }

}

export default new Coop();